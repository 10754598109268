<template>
  <el-dialog
    class="verVisible"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="ver-panel">
      <div class="ver-head">
        <div class="l-title">绑定邮箱</div>
        <div class="l-desc">邮箱绑定后，可通过邮箱+密码的方式登陆</div>
      </div>
      <el-form :model="ruleForm" ref="emailForm">
        <el-form-item prop="phone">
          <div class="ver-phone-cell">请输入要绑定的邮箱</div>
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="ruleForm.email" placeholder="请输入邮箱">
            <el-button
              slot="suffix"
              class="btn btn-getcode"
              :disabled="sended"
              @click="sendEmail"
              >{{
                sended ? `${countDown}秒后再次发送` : "获取邮箱验证码"
              }}</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="ruleForm.code" placeholder="请输入验证码">
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="ver-foot">
            <el-button
              class="btn-submit"
              type="primary"
              @click="submitForm('ruleForm')"
              >验证</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { sendVerificationCode, bandEmailOrPhone } from "@/service/index";
import { Loading } from "element-ui";
import { validEmail } from "@/utils/index";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ruleForm: {
        email: "",
      },
      sended: false,
      countDown: 60,
    };
  },
  mounted() {},
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("change-visible", val);
      },
    },
  },
  methods: {
    submitForm() {
      this.$refs.emailForm.validate((valid) => {
        if (valid) {
          bandEmailOrPhone(this.ruleForm.email, this.ruleForm.code).then(
            (res) => {
              console.log(res);
              if (res.success) {
                this.dialogVisible = false;
                this.$emit("reload");
              } else {
                return this.$message.error(res.message);
              }
            }
          );
        }
      });
    },
    slideValidation(cb) {
      let captchaId = "2060393098";
      const captcha = new TencentCaptcha(captchaId, async (res) => {
        console.log(res);
        if (res.ret === 0) {
          cb();
        }
      });
      captcha.show();
    },
    sendEmail() {
      if (this.ruleForm.email == "") {
        return this.$message.error("请输入邮箱");
      }
      if (!validEmail(this.ruleForm.email)) {
        return this.$message.error("请输入合法邮箱");
      }
      this.slideValidation(() => {
        sendVerificationCode(this.ruleForm.email).then((res) => {
          if (res.success) {
            this.$message.success("发送验证码成功，请查收邮件！");
            this.$refs.emailForm.clearValidate();
            this.sended = true;
            const countDown = setInterval(() => {
              this.countDown = this.countDown - 1;
              if (this.countDown == 0) {
                clearInterval(countDown);
                this.countDown = 60;
                this.sended = false;
              }
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.verVisible ::v-deep .el-dialog {
  border-radius: 10px;
}
.verVisible ::v-deep .el-dialog__body {
  padding: 10px 30px;
}
.ver-head {
  height: 70px;
  text-align: center;
  color: #333;
  .l-title {
    font-size: 16px;
  }
  .l-sub-title {
    font-size: 13px;
  }
  .l-desc {
    color: #999;
    margin-top: 10px;
    font-size: 12px;
  }
}
.btn-getcode {
  display: inline-flex;
  border: 0;
  color: #007df7;
  padding: 8px 10px;
  cursor: pointer;
}
.ver-panel ::v-deep .el-input__inner {
  background: #f8f8f8;
  border-radius: 10px;
  border-color: #f8f8f8 !important;
}
.ver-foot {
  text-align: center;
}
.ver-panel .btn-submit {
  width: 60%;
  margin: 0;
  border-radius: 10px;
}
.ver-panel .btn-submit:not(.is-disabled) {
  background: linear-gradient(90deg, #007bf7 0%, #0aabfb 100%);
}
.flex_bd {
  flex: 1;
  min-width: 0;
}
.input-select-group {
  position: relative;
}
.input-select-group .el-select {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 119;
  width: 120px;
}

.input-select-group .el-input ::v-deep .el-input__inner {
  padding-left: 140px !important;
}
.ver-phone-cell {
  font-size: 14px;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  color: #c0c4cc !important;
}
</style>
