<template>
  <el-dialog
    class="verVisible"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="ver-panel">
      <div class="ver-head">
        <div class="l-title">新密码绑定</div>
        <div class="l-desc"></div>
      </div>
      <el-form :model="ruleForm" ref="passwordForm" :rules="rules">
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            type="password"
            placeholder="请输入新密码"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="repassword">
          <el-input
            v-model="ruleForm.repassword"
            type="password"
            placeholder="请输入确认密码"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="ver-foot">
            <el-button class="btn-submit" type="primary" @click="submitForm"
              >提交</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { setPassword } from "@/service/index";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    var checkPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("新密码不能为空"));
      }
      const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,16}$/;
      if (!regex.test(value)) {
        return callback(new Error("密码为长度6-16位字符，必须包含数字和字母"));
      }
      callback();
    };
    var checkRePassword = (rule, value, callback) => {
      if (value != this.ruleForm.password) {
        return callback(new Error("两次输入的密码不一致"));
      }
      callback();
    };

    return {
      ruleForm: {
        password: "",
        repassword: "",
      },
      rules: {
        password: [
          { required: true, validator: checkPassword, trigger: "blur" },
        ],
        repassword: [
          { required: true, validator: checkRePassword, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    submitForm() {
      this.$refs.passwordForm.validate((valid) => {
        if (this.ruleForm.password != this.ruleForm.repassword) {
          return this.$message.warning("两次输入的密码不一致，请重新输入");
        }
        if (valid) {
          setPassword({
            password: this.ruleForm.password,
          }).then((res) => {
            if (res.success) {
              this.$message.success("设置密码成功");
              this.dialogVisible = false;
              this.$emit("reload");
            }
          });
        }
      });
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("change-visible", val);
      },
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.ruleForm = {
          password: "",
        };
      }
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.verVisible ::v-deep .el-dialog {
  border-radius: 10px;
}
.verVisible ::v-deep .el-dialog__body {
  padding: 10px 30px;
}
.ver-head {
  height: 60px;
  text-align: center;
  color: #333;
  .l-title {
    font-size: 16px;
  }
  .l-sub-title {
    font-size: 13px;
  }
  .l-desc {
    color: #999;
    margin-top: 10px;
    font-size: 12px;
  }
}
.btn-getcode {
  display: inline-flex;
  border: 0;
  color: #007df7;
  padding: 8px 10px;
  cursor: pointer;
}
.ver-panel ::v-deep .el-input__inner {
  background: #f8f8f8;
  border-radius: 10px;
  border-color: #f8f8f8 !important;
}
.ver-foot {
  text-align: center;
}
.ver-panel .btn-submit {
  width: 60%;
  margin: 0;
  border-radius: 10px;
}
.ver-panel .btn-submit:not(.is-disabled) {
  background: linear-gradient(90deg, #007bf7 0%, #0aabfb 100%);
}
.flex_bd {
  flex: 1;
  min-width: 0;
}
.input-select-group {
  position: relative;
}
.input-select-group .el-select {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 119;
  width: 120px;
}

.input-select-group .el-input ::v-deep .el-input__inner {
  padding-left: 140px !important;
}
.ver-phone-cell {
  font-size: 14px;
}
</style>
