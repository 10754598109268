import { render, staticRenderFns } from "./VerifyPhone.vue?vue&type=template&id=75cb9eab&scoped=true"
import script from "./VerifyPhone.vue?vue&type=script&lang=js"
export * from "./VerifyPhone.vue?vue&type=script&lang=js"
import style0 from "./VerifyPhone.vue?vue&type=style&index=0&id=75cb9eab&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75cb9eab",
  null
  
)

export default component.exports