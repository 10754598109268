import { render, staticRenderFns } from "./VerifyEmailBind.vue?vue&type=template&id=71e97df7&scoped=true"
import script from "./VerifyEmailBind.vue?vue&type=script&lang=js"
export * from "./VerifyEmailBind.vue?vue&type=script&lang=js"
import style0 from "./VerifyEmailBind.vue?vue&type=style&index=0&id=71e97df7&prod&scoped=scoped&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e97df7",
  null
  
)

export default component.exports