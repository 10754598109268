<template>
  <el-dialog
    class="verVisible"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="ver-panel" v-if="!isVerified">
      <div class="ver-head">
        <div class="l-title">身份验证</div>
        <div class="l-desc">
          为了保护你的账户安全，请验证身份<br />
          验证成功后进行下一步操作
        </div>
      </div>
      <el-form :model="verifyForm" ref="verifySmsCodeForm" :rules="rules">
        <el-form-item prop="phoneOrEmail">
          <div class="ver-phone-cell">使用手机+{{ phone }}验证</div>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="verifyForm.code" placeholder="请输入短信验证码">
            <el-button
              @click="sendVerificationCode"
              :disabled="sended"
              slot="suffix"
              class="btn btn-getcode"
              >{{
                sended ? `${countDown}秒后再次发送` : "获取短信验证码"
              }}</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="ver-foot">
            <el-button class="btn-submit" type="primary" @click="submitForm1"
              >验证</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="ver-panel" v-else>
      <div class="ver-head">
        <div class="l-title">更换/绑定手机号</div>
        <div class="l-desc"></div>
      </div>
      <el-form :model="changeForm" :rules="rules2" ref="changeForm">
        <el-form-item prop="phone">
          <div class="input-select-group" :class="'select' + changeForm.select">
            <el-select
              v-model="changeForm.select"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option
                v-for="item in countrys"
                :label="item.label"
                :key="item.val"
                :value="item.val"
              ></el-option>
            </el-select>
            <div class="accountSeperator"></div>
            <div class="flex-col">
              <el-input v-model="changeForm.phone" placeholder="请输入手机号">
              </el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="changeForm.code" placeholder="请输入短信验证码">
            <el-button
              @click="sendVerificationCode2"
              :disabled="sended"
              slot="suffix"
              class="btn btn-getcode"
              >{{
                sended ? `${countDown}秒后再次发送` : "获取短信验证码"
              }}</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="ver-foot">
            <el-button class="btn-submit" type="primary" @click="submitForm2"
              >验证</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {
  getUserInfoData,
  sendUpdatePhoneSms,
  checkUserOldPhoneAndCode,
  sendVerificationCode,
  updatePhone,
  bandEmailOrPhone,
} from "@/service/index.js";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      setTimeout(() => {
        if (!regex.test("+" + this.changeForm.select + value)) {
          return callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      verifyForm: {
        code: "",
      },
      changeForm: {
        select: "86",
      },
      phone: "",
      isVerified: false,
      rules: {
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      rules2: {
        phone: [{ required: true, validator: checkMobile, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      sended: false,
      countDown: 60,
      countrys: [
        {
          label: "+86 中国",
          val: "86",
        },
        {
          label: "+1 美国 & 加拿大",
          val: "1",
        },
        {
          label: "+886 台湾",
          val: "886",
        },
        {
          label: "+852 香港",
          val: "852",
        },
        {
          label: "+61 澳大利亚",
          val: "61",
        },
        {
          label: "+44 英国",
          val: "44",
        },
        {
          label: "+49 德国",
          val: "49",
        },
        {
          label: "+353 爱尔兰",
          val: "353",
        },
        {
          label: "+41 瑞士",
          val: "41",
        },
        {
          label: "+31 荷兰",
          val: "31",
        },
        {
          label: "+33 法国",
          val: "33",
        },
        {
          label: "+34 西班牙",
          val: "34",
        },
        {
          label: "+64 新西兰",
          val: "64",
        },
        {
          label: "+7 俄罗斯",
          val: "7",
        },
        {
          label: "+82 韩国",
          val: "82",
        },
        {
          label: "+81 日本",
          val: "81",
        },
        {
          label: "+971 阿拉伯联合酋长国",
          val: "971",
        },
      ],
      interval: null,
      hasPhone: false,
    };
  },
  mounted() {},
  methods: {
    submitForm1() {
      this.$refs.verifySmsCodeForm.validate((valid) => {
        if (valid) {
          checkUserOldPhoneAndCode({ code: this.verifyForm.code }).then(
            (res) => {
              if (res.success) {
                this.$refs.verifySmsCodeForm.clearValidate();
                this.isVerified = true;
                this.interval && clearInterval(this.interval);
                this.sended = false;
                this.countDown = 60;
              } else {
                this.$message.error(res.message);
              }
            }
          );
        }
      });
    },
    submitForm2() {
      this.$refs.changeForm.validate((valid) => {
        if (valid) {
          if (this.hasPhone) {
            updatePhone({
              phoneOrEmail: `${this.changeForm.select}${this.changeForm.phone}`,
              code: this.changeForm.code,
            }).then((res) => {
              if (res.success) {
                this.interval && clearInterval(this.interval);
                this.sended = false;
                this.countDown = 60;
                this.$message.success("更换手机成功！");
                this.dialogVisible = false;
                this.isVerified = false;
                this.$emit("reload");
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            bandEmailOrPhone(
              `${this.changeForm.select}${this.changeForm.phone}`,
              this.changeForm.code
            ).then((res) => {
              if (res.success) {
                this.interval && clearInterval(this.interval);
                this.sended = false;
                this.countDown = 60;
                this.$message.success("绑定手机成功！");
                this.dialogVisible = false;
                this.isVerified = false;
                this.$emit("reload");
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
    slideValidation(cb) {
      let captchaId = "2060393098";
      const captcha = new TencentCaptcha(captchaId, async (res) => {
        console.log(res);
        if (res.ret === 0) {
          cb();
        }
      });
      captcha.show();
    },
    getUserInfoData() {
      getUserInfoData().then((res) => {
        this.phone = res.result.phone;
        if (res.result.phone) {
          this.hasPhone = true;
        } else {
          this.hasPhone = false;
        }
        if (!res.result.phone) {
          this.isVerified = true;
        }
      });
    },
    sendVerificationCode() {
      this.slideValidation(() => {
        sendUpdatePhoneSms().then((res) => {
          if (res.success) {
            this.$message.success("发送验证码成功！");
            this.sended = true;
            this.interval = setInterval(() => {
              this.countDown = this.countDown - 1;
              if (this.countDown == 0) {
                clearInterval(this.interval);
                this.countDown = 60;
                this.sended = false;
              }
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    sendVerificationCode2() {
      const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      if (!this.changeForm.phone) {
        return this.$message.error("请输入手机号!");
      }
      if (!regex.test("+" + this.changeForm.select + this.changeForm.phone)) {
        return this.$message.error("请输入正确的手机号!");
      }
      this.slideValidation(() => {
        sendVerificationCode(
          `${this.changeForm.select}${this.changeForm.phone}`
        ).then((res) => {
          if (res.success) {
            this.$message.success("发送验证码成功！");
            this.sended = true;
            this.interval = setInterval(() => {
              this.countDown = this.countDown - 1;
              if (this.countDown == 0) {
                clearInterval(this.interval);
                this.countDown = 60;
                this.sended = false;
              }
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("change-visible", val);
      },
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getUserInfoData();
        this.verifyForm = {
          code: "",
        };
        this.changeForm = {
          select: "86",
        };
        this.$refs.verifySmsCodeForm.clearValidate();
        this.$refs.changeForm.clearValidate();
      }
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.verVisible ::v-deep .el-dialog {
  border-radius: 10px;
}
.verVisible ::v-deep .el-dialog__body {
  padding: 10px 30px;
}
.ver-head {
  height: 80px;
  text-align: center;
  color: #333;
  .l-title {
    font-size: 16px;
  }
  .l-sub-title {
    font-size: 13px;
  }
  .l-desc {
    color: #999;
    margin-top: 10px;
    font-size: 12px;
  }
}
.btn-getcode {
  display: inline-flex;
  border: 0;
  color: #007df7;
  padding: 8px 10px;
  cursor: pointer;
  &.is-disabled {
    color: #c0c4cc;
  }
}
.ver-panel ::v-deep .el-input__inner {
  background: #f8f8f8;
  border-radius: 10px;
  border-color: #f8f8f8 !important;
}
.ver-foot {
  text-align: center;
}
.ver-panel .btn-submit {
  width: 60%;
  margin: 0;
  border-radius: 10px;
}
.ver-panel .btn-submit:not(.is-disabled) {
  background: linear-gradient(90deg, #007bf7 0%, #0aabfb 100%);
}
.input-select-group {
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border-radius: 10px;
  border-color: #f8f8f8 !important;
  position: relative;
}
.input-select-group .el-select {
  width: 110px;
}
.input-select-group.select1 .el-select {
  width: 156px;
}
.input-select-group.select353 .el-select,
.input-select-group.select34 .el-select,
.input-select-group.select64 .el-select {
  width: 130px;
}
.input-select-group.select61 .el-select {
  width: 136px;
}
.input-select-group.select971 .el-select {
  width: 200px;
}

.accountSeperator {
  background: #ebeced;
  height: 22px;
  width: 1px;
}
.flex-col {
  flex: 1;
  min-width: 0;
}
.ver-phone-cell {
  font-size: 14px;
}
</style>
